require("./bootstrap");

$(document).ready(function () {
    AX.init();
})

AX.init = function() {
    AX.initBootstrapPlugins();
    AX.initHoverSelect();
    AX.initNavbarShadow();
    AX.initBootstrapSelect();
    AX.initBackToTop($("#backToTop"));
    AX.attachTableEventHandlers();
	AX.forms.attachEventHandlers();
};