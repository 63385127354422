// Initialize date range picker with default start/end time of 30 days from now.
AX.daterangepicker = function(el, drops = "down") {
    var $el = $(el);

    if ( ! $el.length) return;

    var startDate, endDate;
    if ($el.val() === "") {
        startDate = moment().subtract(6, "days");
        endDate = moment()
    } else {
        var dates = $el
            .val()
            .split(" - ");
        startDate = dates[0];
        endDate = dates[1];
    }

    $el.daterangepicker({
        startDate: startDate,
        endDate: endDate,
        drops: drops,
        opens: "left",
        ranges: {
            Today: [moment(), moment()],
            Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days")
            ],
            "Last 7 Days": [
                moment().subtract(6, "days"),
                moment()
            ],
            "Last 30 Days": [
                moment().subtract(29, "days"),
                moment()
            ],
            "This Month": [
                moment().startOf("month"),
                moment().endOf("month")
            ],
            "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month")
            ],
            "Last 3 Months": [
                moment().subtract(3, "month"),
                moment()
            ]
        }
    });

    $el.on("apply.daterangepicker", function(ev, picker) {
        console.log(picker.startDate.format('YYYY-MM-DD HH:MM:SS'));
        console.log(picker.endDate.format('YYYY-MM-DD HH:MM:SS'));
        $("#subnavDateFilterForm").submit();
    });
};

// Add perfect scrollbar to div
AX.scrollbar = function (el) {
    if (window.matchMedia("(max-width: 767px)").matches) return;

    if ($(el).length) {
        sb = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
        });

        return sb;
    }

    return false;
};

// Initialize bootstrap select pluing on all select inputs
AX.initBootstrapSelect = function () {
    if ($('select').length) {
        $.fn.selectpicker.Constructor.BootstrapVersion = "4";
        $("select").selectpicker({
            mobile: false
        });

        $(document).on('rendered.bs.select', function () {
            $(this).find('.dropdown-toggle').removeAttr('title');
        });
    }
};

// Initialize popover/tooltips
AX.initBootstrapPlugins = function () {
    $('[data-toggle="tooltip"], [data-toggle-second="tooltip"]').tooltip({
        trigger: 'hover'
    });
    
    $('[data-toggle="popover"], [data-toggle-second="popover"]').popover({
        trigger: 'hover',
        html: true
    });
};