AX.modals = AX.modals || {};

// Register click handler for ajax modals.
$(document).on('click', '[data-toggle="ax-ajax-modal"]', function (event) {
    AX.modals.ajaxModal(event);
    event.preventDefault();
});

// Register click handler for lightbox modals.
$(document).on('click', '[data-toggle="ax-lightbox"]', function (event) {
    var $target = $(event.target)
    ,   $image = $target.closest('img');

    AX.modals.modal({
        action: '',
        title: 'Image',
        icon: 'fa fa-image',
        message: '<div class="text-center"><img class="ax-lightbox-img" src="' + $image.attr('src') + '" /></div>',
        id: 'lightboxModal',
        size: 'fluid modal-lightbox',
        cancel: false,
        buttons: [
            '<button class="btn btn-secondary btn-md mr-1" data-dismiss="modal"><i class="far fa-times-circle pr-1"></i> Close</button>'
        ]
    });

    // AX.modals.ajaxModal(event);
    event.preventDefault();
});

// Dynamic modal with content loaded via ajax request. Settings passed via data tags.
AX.modals.ajaxModal = function (event) {
    var $this    = $(event.target).closest('[data-toggle="ax-ajax-modal"]')
    ,   $modal   = $("#modalDefault").clone()
    ,   settings = $this.data()
    ,   route    = settings.route || '/ajax/modal';

    // 'lg', 'md', 'sm' or nothing at all.
    if (settings.size) {
        $modal.find('.modal-dialog').addClass('modal-' + settings.size);
    }
   
    $modal.find('.modal-title-inner').text(settings.title || 'AX modal title');
    $modal.find('.modal-title-icon').addClass(settings.icon || 'fa fa-check-circle');
    $modal.attr('id', settings.id || 'generatedAjaxModal_' + Date.now());
    $modal.hide();

    if (typeof settings.view === 'undefined') {
        throw new Error('You must pass a view script path in dot notation to load an ajax modal.');
    }

    $modal.find('.modal-preloader').fadeIn(150);

    // Load the modal content.
    $modal.find('.modal-body-ajax').hide().load(route, { 
        id   : $modal.attr('id'), 
        view : settings.view, 
        data : settings.params,
    }, function () {
        $modal.find('.modal-preloader').fadeOut(150, function () {
            AX.init();
            $(this).remove();
            $modal.find('.modal-body-ajax').slideDown(300, function (event) {
                // Focus on first visible input or select after modal is loaded.
                $modal.children().find('input.form-control:visible, .dropdown-toggle:visible').first().focus();
            });
        });
    });

    // Destroy modal on close.
    $(document).on('hidden.bs.modal', '#' + $modal.attr('id'), function (event) {
        console.log('Removing modal #' + $modal.attr('id'));
        $(this).remove();
    });

    $modal.modal('show');
};

// Generic js modal.
AX.modals.modal = function (settings) {
    var $modal = $("#modalDefault").clone()
    ,   cancel = '<button class="btn btn-secondary btn-md mr-1" data-dismiss="modal"><i class="far fa-times-circle pr-1"></i> Cancel</button>'
    ,   defaultButtons = [cancel];

    // 'xl', 'lg', 'md', 'sm' or nothing at all will default to 'md'
    if (settings.size) {
        $modal.find('.modal-dialog').addClass('modal-' + settings.size);
    }
   
    if (settings.ajax) {
        $modal.find('form').attr('data-ajax-form', '');
    }

    $modal.attr('id', settings.id || 'generatedModal_' + Date.now());
    $modal.find('.modal-title-inner').text(settings.title || 'AX modal title');
    $modal.find('.modal-title-icon').addClass(settings.icon || 'fa fa-check-circle');
    $modal.find('form').attr('method', settings.method || 'POST').attr('action', settings.action || '');
    $modal.find('.modal-body').html(settings.message || 'AX modal message body');
    $modal.find('.modal-footer').html((settings.cancel ? cancel : '') + ((settings.buttons || settings.cancel) || defaultButtons).join(""));
    $modal.hide();

    typeof settings.onInit === 'function' && settings.onInit($modal);

    $modal.modal('show');

    // Optional event callbacks.
    $modal.on('show.bs.modal', function (e) {
        typeof settings.onShow === 'function' && settings.onShow($modal);
    });
    $modal.on('shown.bs.modal', function (e) {
        AX.forms.attachEventHandlers();
        typeof settings.onShown === 'function' && settings.onShown($modal);
    });
    $modal.on('hide.bs.modal', function (e) {
        typeof settings.onHide === 'function' && settings.onHide($modal);
    });
    $modal.on('hidden.bs.modal', function (e) {
        typeof settings.onHidden === 'function' && settings.onHidden($modal);
        $modal.remove();
    });
};

// Delete a resource using data tags.
AX.modals.deleteModal = function (event) {
    var $target = $(event.target).closest('[data-route]')
    ,   action = $target.data('route')
    ,   resource = $target.data('resource') || 'Resource'
    ,   message = $target.data('message') || 'Are you sure you want to delete this ' + _.lowerCase(resource) + '?'
    ,   icon = $target.data('icon') || 'far fa-trash-alt'
    ,   isAjax = $target.data('ajax') || false
    ,   id = $target.data('id') || 'generatedDeleteModal_' + Date.now()
    ,   title = $target.data('title') || 'Delete ' + resource;

    if (typeof action === 'undefined') {
        throw new Error('No action url defined.');
    }

    AX.modals.modal({
        action: action,
        title: title,
        icon: icon,
        message: message,
        cancel: true,
        ajax: isAjax,
        id: id,
        buttons: [
            '<input type="hidden" name="_method" value="delete" />',
            '<button type="submit" class="btn btn-primary"><i class="far fa-trash-alt pr-1"></i> Delete</button>'
        ]
    });
};

// Confirm an action using data tags.
AX.modals.confirmModal = function (event) {
    var $target = $(event.target).closest('[data-route]')
    ,   action = $target.data('route')
    ,   method = $target.data('method') || 'GET'
    ,   message = $target.data('message') || 'Are you sure you want to?'
    ,   title = $target.data('title') || 'Please Confirm'
    ,   icon = $target.data('icon') || 'far fa-check-circle'
    ,   isAjax = $target.data('ajax') || false
    ,   id = $target.data('id') || 'generatedConfirmModal_' + Date.now()
    ,   confirmText = $target.data('button') || 'Continue';

    if (typeof action === 'undefined') {
        throw new Error('No action url defined.');
    }

    AX.modals.modal({
        action: action,
        method: method,
        icon: icon,
        title: title,
        message: message,
        cancel: true,
        ajax: isAjax,
        id: id,
        buttons: [
            '<button type="submit" class="btn btn-primary"><i class="far fa-check-circle pr-1"></i> ' + confirmText + '</button>',
        ]
    });
};