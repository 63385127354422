// Default data table settings
AX.dataTable = function(el, settings) {
    var $el = $(el);

    $.fn.dataTable.ext.errMode = 'none'; 
    $el.on('error.dt', function(e, settings, techNote, message) { 
        console.log('DataTable error occurred: ', message);
        $el.parents('.datatable-wrapper').fadeIn();
    });

    var defaults = {
        order : [
            [ 
                settings.sort      || 0, 
                settings.direction || 'desc'
            ]
        ],
        info         : settings.info         || false,
        paging       : settings.paging       || false,
        searching    : settings.searching    || false,
        lengthChange : settings.lengthChange || false,
        language: { 
            processing        : '<i class="fa fa-spin fa-cog fa-2x red"></i>',
            search            : "",
            info              : "Page _PAGE_ of _PAGES_",
            infoEmpty         : "",
            zeroRecords       : settings.zeroRecords || "No records found",
            infoFiltered      : "(Filtered from _MAX_ records)",
            loadingRecords    : '&nbsp;',
            searchPlaceholder : "Search...",
        },
        initComplete: function() {
            $el.parents('.datatable-wrapper').fadeIn();
            $(document).trigger('ax.datatable.initialized', { el: el });
        }
    };

    if (settings.export) {
        $.extend(defaults, {
            dom: 'Bfrtip',
            buttons: [ 'excelHtml5' ],
            initComplete: (options, json) => {
                $('.buttons-excel').removeClass('btn-secondary').addClass('btn-primary btn-sm').html('<i class="fa fa-file-download pr-1"></i> Export');
                $el.parents('.datatable-wrapper').fadeIn();
                $(document).trigger('datatable-initialized', { el: el });
            },
        });
    };

    var tableOptions = defaults;

    if ($(el + ' .datatable-row').length) {
        if (settings.ajaxData) {
            var ajaxSettings = {
                paging: true,
                searching: true,
                info: true,
                initComplete: (options, json) => {
                    $('<div id="' + el.slice(1) + '_footer" class="card-footer py-2 px-3 d-flex align-items-center justify-content-between m-0"></div>').insertAfter($el.closest('.card').find('.card-body'));
                    $(el + '_filter').appendTo($(el + '_footer'));
                    $(el + '_info').appendTo($(el + '_footer'));
                    $(el + '_paginate').appendTo($(el + '_footer'));
                    $el.parents('.datatable-wrapper').fadeIn();
                    $(document).trigger('ax.datatable.initialized', { el: el });
                },
                scrollY: ($el.closest('.card-body').outerHeight() - $el.find('th:first').outerHeight() - 51) + 'px',
                scrollCollapse: true,
            };

            tableOptions = {...defaults, ...ajaxSettings, ...settings.ajaxData};
        }

        return $el.DataTable(tableOptions);
    }

    $(document).trigger('ax.datatable.error', { el: el });
    $el.parents('.datatable-wrapper').fadeIn();
    console.log('Unable to initialize DataTable for ' + el);
};

// Open drawer for datatable
AX.toggleDataTableDrawer = function (callback, target) {
    if (typeof target === 'undefined') {
        var $target = $(event.target);
    } else {
        var $target = $(target); 
    }

    var tr    = $target.closest('tr')
    ,   table = tr.closest('table').DataTable()
    ,   row   = table.row(tr);

    if ($target.is('a') || $target.is('button') || $target.is('input') || $target.parent().hasClass('no-expand')) return;
    
    if ( ! row.child.isShown() ) {
        row.child(callback(tr.data('drawer')), 'no-padding').show();
        tr.addClass('shown');
        $('div.slider', row.child()).slideDown(250);
    } else {
        $('div.slider', row.child()).slideUp(250, function () {
            row.child.hide();
            tr.removeClass('shown');
        });
    }
    
    return false;
};

// Open all drawers for a specific datatable.
AX.toggleAllDataTableDrawers = function (tableId, callback) {
    var $table = $(tableId)
    ,   $arrow = $table.closest('.card').find('.arrow')
    ,   $rows  = $table.find('.has-drawer');

    if ($arrow.hasClass('up-arrow')) {
        $arrow.removeClass('up-arrow').addClass('down-arrow');
    } else {
        $arrow.removeClass('down-arrow').addClass('up-arrow');
    }

    if ( ! $rows.length) {
        return;        
    } else if ($rows.length === 1) {
        AX.toggleDataTableDrawer(callback, $rows);
    } else {
        $rows.each(function (i, tr) {
            AX.toggleDataTableDrawer(callback, $(tr));
        });
    }
};