window._ = require("lodash");
window.$ = window.jQuery = require("jquery");

try {
    window.Popper = require("popper.js").default;
    window.moment = require("moment");
    window.PerfectScrollbar = require("perfect-scrollbar/dist/perfect-scrollbar");

    // Load npm vendor packages needed sitewide.
    require("bootstrap");
    require('datatables.net-bs4');
    require("daterangepicker");

    window.AX = {};
    
    // Basic app modules, need to be heavily refactored.
    require("./vendor");
    require("./forms");
    require("./datatables");
    require("./utils");
    require("./alerts");
    require("./modals");
} catch (e) {
    console.log(e);
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Add csrf headers for jQuery requests as well.
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
