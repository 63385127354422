AX.forms = AX.forms || {};

// Add any misc form related event handlers here.
AX.forms.attachEventHandlers = function () {
	// Checkbox toggleswitch handler
	$('.pretty.p-switch input[type="checkbox"]').off().on('change', function (event) {
		AX.forms.toggleSwitchHandler(event);
	});

	// Ajax form submit handler.
	$('form[data-ajax-form]').off().on('submit', function (event) {
        AX.forms.handleAjaxSubmit(event);
	});

    // Handle toggle switch changes.
    $(document).off('ax.toggle.change').on('ax.toggle.change', function (event, data) {
        AX.forms.handleAjaxToggle(event, data);
    });
};

// Catch a form submit and submit via ajax.
AX.forms.handleAjaxSubmit = function (event) {
    event.preventDefault();
    event.stopPropagation();

    var $form = $(event.target)
    ,   action = $form.attr('action')
    ,   method = $form.attr('method')
    ,   $button = $form.find('button[type="submit"]')
    ,	btnHtml = $button.html()
    ,   preloaderHtml = '<i class="fa fa-spin fa-spinner"></i>';

    // Disable button and show preloader.
	$button.css('width', $button.css('width')).html(preloaderHtml).prop('disabled', true);

    $.ajax({
        url: action,
        method: method,
        data: $form.serializeArray()
    })
    .done(function (response) {
        var reload = true
        ,   redirect = false;

        // Check for reload/redirect params.
        if (typeof response.data !== 'undefined') {
            if (typeof response.data.reload !== 'undefined') {
                reload = response.data.reload;
            }
            if (typeof response.data.redirect !== 'undefined') {
                redirect = response.data.redirect;
            }
        }

        if (response.status == 200) {
            AX.alert('success', response.message);
            if (redirect) {
                window.location.href = redirect;
            } else {
                $form.trigger('ax.form.success', { 
                    $form: $form, 
                    response: response,
                    reload: reload
                });
            }
        } else {
            console.log(response);
            AX.alert('error', response.message);
            $form.trigger('ax.form.error', { 
                $form: $form, 
                response: response, 
                reload: reload
            });
        }
        
    	$button.html(btnHtml).prop('disabled', false);
    })
    .fail(function (jqXHR, textStatus, err) {
        console.log(err);
        AX.alert('error', err);
        $form.trigger('ax.form.fail', { 
            $form: $form, 
            response: err, 
            reload: false 
        });
        $button.html(btnHtml).prop('disabled', false);
    })
    .always(function () {
        $button.html(btnHtml).prop('disabled', false);
    });

    return false;
};

// Event handler for "pretty checkbox" package toggle switch checkboxes.
AX.forms.toggleSwitchHandler = function (event) {
    var $this = $(event.target).closest('input[type="checkbox"]');

    $(document).trigger('ax.toggle.change', {
        target: event.target,
        el: $this.attr('name') || '',
        checked: $this.is(':checked') ? 1 : 0
    });

	if ($this.is(':checked')) { // Enabled
		$('#' + $this.attr('aria-describedby') + ' .tooltip-inner').text('Disable');

		$this.attr('data-original-title', 'Disable').tooltip('update');

		// Trigger events so we can react to different states.
		$(document).trigger('ax.toggle.enabled', {
			el: $this.attr('name') || ''
		});
	} else { // Disabled
		$('#' + $this.attr('aria-describedby') + ' .tooltip-inner').text('Enable');
		
		$this.attr('data-original-title', 'Enable').tooltip('update');

		// Trigger events so we can react to different states.
		$(document).trigger('ax.toggle.disabled', {
			el: $this.attr('name') || ''
		});
	}
};

// Event handler for "pretty checkbox" package toggle switch checkboxes.
AX.forms.handleAjaxToggle = function (event, data) {
    var $target = $(data.target);

    $.ajax({
        url: $target.attr('data-route'),
        method: 'POST',
        data: {
            enabled: data.checked
        }
    })
    .done(function (response) {
        if (response.status === 200) {
            AX.alert('success', response.message);
            if (data.checked) {
                $target.parents('tr').removeClass('toggle-row-disabled');
            } else {
                $target.parents('tr').addClass('toggle-row-disabled');
            }
            $target.trigger('ax.toggle.success', { 
                $toggle: $target, 
                response: response,
            });
        } else {
            console.log(response);
            AX.alert('error', response.message);
            $target.trigger('ax.toggle.error', { 
                $toggle: $target, 
                response: response,
            });
        }
    })
    .fail(function (jqXHR, textStatus, err) {
        console.log(err);
        AX.alert('error', err);
        $target.trigger('ax.toggle.error', { 
            $toggle: $target, 
            response: response,
        });
    });
};