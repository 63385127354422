// Format money string from $10,000 to $10k or $1,000,000 to $1M and so on
AX.formatNum = function(number) {
    var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

    // what tier? (determines SI symbol)
    var tier = (Math.log10(number) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(0).toLocaleString() + suffix;
};

// Open first filtered item if there is one, or the first filter if not.
AX.toggleFirstActiveFilter = function(animated = false, callback) {
    var $filter = $('.is-filtered').first();
    if (animated) {
        // Give bootstrap select a chance to load so we dont see the flicker
        setTimeout(function () {
            var $filter = $('.is-filtered').first();
            if ($filter.length) {
                $filter.parent().trigger('click');
            } else {
                $('.filter-toggle').trigger('click');
            }
        }, 125);
    } else {
        if ($filter.length) {
            $filter.parent().attr('data-aria-expanded', true).next().addClass('show');
        } else {
            $('.filter-toggle').first().attr('data-aria-expanded', true).next().addClass('show');
        }
    }

    // Fire callback.
    if (typeof callback === 'function') {
        $(document).one('shown.bs.collapse', $filter, function (event) {
            callback(event, { filter: $filter });
        });
    }
};

// Clear filter sidebar input selects/inputs
AX.clearFilter = function(event, filterName) {
    event.preventDefault();

    var $target = $(event.target);

    if ($target.prev().is('input')) {
        $("input[name=" + filterName + "]").val("").focus();
        $target.fadeOut();
    } else {
        $("select[name=" + filterName + "]").val("").selectpicker("refresh").data('selectpicker').$button.focus();
    }

    return false;
};

// Open link via js
AX.open = function (event, url, popup) {
    $target = $(event.target);
    // Ignore window.open call on table row clicks if user is clicking a link or button
    if ($target.is('a') || $target.is('button') || $target.parent().is('a') || $target.is('input')) return;
    if (typeof popup != 'undefined' && popup) window.open(url, '_blank');
    else window.location.href = url;
};

// Scroll to top of page
AX.scrollToTop = function (speed) {
    speed = speed || 300;
    $("html, body").animate({ scrollTop: 0 }, speed);
};

// Setup back to top button
AX.initBackToTop = function($btn) {
    // Handle initial page load
    toggleBTTButton();

    // Show/hide button on scroll
    $(window).scroll(toggleBTTButton);

    // Scroll on click
    $btn.on("click", function(event) {
        event.preventDefault();
        AX.scrollToTop(300);
    });

    function toggleBTTButton() {
        if ($(window).scrollTop() > 300) {
            $btn.fadeIn(500);
        } else {
            $btn.fadeOut(250);
        }
    }
};

// Add open on hover option to selectpicker
AX.initHoverSelect = function() {
    if (!$('.select-hover-open').length) return;

    // Open on mouse enter after slight delay
    $(".bootstrap-select.select-hover-open").on('mouseenter', function(event) {
        _togglePicker(this, 'enter');
    });

    // Open on mouse enter after slight delay
    $(".bootstrap-select.select-hover-open").on('mouseleave', function(event) {
        _togglePicker(this, 'leave');
    });

    function _togglePicker(ctx, direction) {
        var $this = $(ctx);
        var $selectpicker = $this.parents().find('.select-hover-open .selectpicker');
        var $parent = $this.closest('.select-hover-open');
        if (direction == 'enter' && $parent.children().hasClass('show')) return;
        if (direction == 'leave' && !$parent.children().hasClass('show')) return;
        $selectpicker.selectpicker('toggle');
    }
};

// Add/remove shadow to navbar on scroll
AX.initNavbarShadow = function () {
    $(window).on('scroll resize', function (event) {
        if ($(this).scrollTop() == 0) {
            $('.subnav').removeClass('with-shadow').addClass('without-shadow');
        } else {
            $('.subnav').removeClass('without-shadow').addClass('with-shadow');
        }
    });
};

// Initialize misc event handlers
AX.attachTableEventHandlers = function () {
    // Enable table drawers
    $(".has-table-drawer").on("click", function(event) {
        AX.orders.toggleTableDrawer(event);
    });

    // Keep dropdown menu's open after clicking on their body if tagged with a 'keep-open' class
    $(".dropdown-menu.keep-open").on("click", function(event) {
        event.stopPropagation();
    });
};